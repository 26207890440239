import { Col, Row, Skeleton, Spin } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { API_URL } from "../../settings";
import axios from "axios";
import HtmlContent from "../HtmlContent";

function CorporateProfile() {
  const [aboutUsId, setAboutUsId] = useState();
  const [content, setContent] = useState<string>();
  const [type, setType] = useState("CORPORATE_PROFILE");
  const [title, setTitle] = useState("");

  const [apiLoader, setApiLoader] = useState(false);

  useEffect(() => {
    getCorporateProfile();
    return () => {};
  }, []);

  const getCorporateProfile = () => {
    setApiLoader(true);
    axios
      .get(`${API_URL}/about-us-by-type/${type}`)
      .then((response) => {
        setApiLoader(false);
        setContent(response.data.description);
        setAboutUsId(response.data.id);
        setTitle(response.data.title);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setApiLoader(false);
      });
  };

  return (
    <Row justify={"center"}>
      <Col span={20}>
          <Title>Corporate Profile</Title>
          <Skeleton active loading={apiLoader}></Skeleton>
          <Skeleton active loading={apiLoader}>
            <div className="default-text">
              <HtmlContent html={content || ""}></HtmlContent>
            </div>
          </Skeleton>
      </Col>
    </Row>
  );
}

export default CorporateProfile;
