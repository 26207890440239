import { Col, Flex, Row } from "antd";
import Marquee from "react-fast-marquee";
import ICompany from "../../interfaces/ICompany";

interface HeaderComponentProps {
  company: ICompany | undefined;
}

function HeadingNews({ company }: HeaderComponentProps) {
  return (
    <>
      <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Col span={24}>
          <Flex>
            <p
              style={{
                fontSize: "24px",
                backgroundColor: "#8080801c",
                paddingLeft: "5px",
                paddingRight: "10px",
                marginBottom: 0,
              }}
            >
              News:
            </p>
            <Marquee
              style={{ fontSize: "24px", border: "#8080801c 1px solid" }}
              pauseOnHover={true}
            >
              {company?.company_latest_news}
            </Marquee>
          </Flex>
        </Col>
      </Row>
    </>
  );
}

export default HeadingNews;
