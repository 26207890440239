import { PlusSquareOutlined } from "@ant-design/icons";
import {
  faArrowsH,
  faArrowsUpDown,
  faBath,
  faBed,
  faBold,
  faChevronRight,
  faComment,
  faCutlery,
  faMoneyBill1,
  faRoad,
  faSpoon,
  faSwimmer,
  faUniversalAccess,
  faUniversity,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Col,
  Divider,
  Flex,
  List,
  Radio,
  RadioChangeEvent,
  Row,
  Skeleton,
  Spin,
} from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { useEffect, useState } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { Link, useParams } from "react-router-dom";
import companyLogo from "../../assets/images/manor_logo.png";
import IItem from "../../interfaces/IItem";
import { API_URL } from "../../settings";
import "./style.css";
import YouTube, { YouTubeProps } from "react-youtube";
import ICompany from "../../interfaces/ICompany";

interface HeaderComponentProps {
    company: ICompany | undefined;
  }
  
function ItemDetails({ company }: HeaderComponentProps) {
  let { id } = useParams();

  const options = [
    { label: "English", value: "English" },
    { label: "Bangla", value: "Bangla" },
  ];

  const [isItemSliderLoaded, setItemSliderLoaded] = useState<boolean>(false);
  const [itemId, setItemId] = useState(id);
  const [itemLoading, setItemLoading] = useState<boolean>(false);
  const [sliderLoading, setSliderLoading] = useState<boolean>(false);
  const [projectCategoryId, setProjectCategoryId] = useState(1);

  const [descriptionLanguage, setDescriptionLanguage] = useState("English");
  const [termsConditionLanguage, setTermsConditionLanguage] =
    useState("English");

  const [item, setItem] = useState<IItem>();
  const [featuresProperties, setFeaturesProperties] = useState<IItem[]>([]);
  const [images, setImages] = useState<ReactImageGalleryItem[]>([]);

  useEffect(() => {
    getItem();
    getFeatureItems();

    return () => {};
  }, [itemId]);

  const getItem = () => {
    setItemLoading(true);
    axios
      .get(`${API_URL}/items/${itemId}`)
      .then((response) => {
        setItem(response.data);
        console.log("response", response);

        response.data.item_sliders.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["name"] = x.image_name;
          x["url"] = API_URL + "/image-download/" + x.image_name;
          x["original"] = API_URL + "/image-download/" + x.image_name;
          x["thumbnail"] = API_URL + "/image-download/" + x.image_name;
          x["originalHeight"] = "500px";
          x["thumbnailHeight"] = "62px";
        });

        setImages(response.data.item_sliders);
        setSliderLoading(false);
        setItemSliderLoaded(true);
        setProjectCategoryId(response.data.project.project_category_id);
        setItemLoading(false);
      })
      .catch((err) => {
        setItemLoading(false);
        console.log("server error", err);
      });
  };

  const getFeatureItems = () => {
    axios
      .get(`${API_URL}/public/items/features/published`)
      .then((response) => {
        setFeaturesProperties(response.data.data);
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  const opts: YouTubeProps["opts"] = {
    width: "100% !important",
    height: "410px !important",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  const onChangeDescriptionLanguage = ({
    target: { value },
  }: RadioChangeEvent) => {
    setDescriptionLanguage(value);
  };

  const onChangeTermsConditionLanguage = ({
    target: { value },
  }: RadioChangeEvent) => {
    setTermsConditionLanguage(value);
  };

  return (
    <>
      <Row justify={"center"} className="mt-1">
        <Col xs={{ span: 22 }} md={{ span: 14 }} span={14}>
          <Row>
            <Col span={24}>
              <Flex className="mb-2" justify={"space-between"}>
                <div style={{ fontSize: "20px" }}>
                  {item?.name} ({item?.item_id})
                </div>
                <div style={{ fontSize: "20px", color: "#1677ff" }}>
                  {new Intl.NumberFormat("en-BD", {
                    style: "currency",
                    currency: "BDT",
                  }).format(item?.net_price ? item?.net_price : 0)}
                </div>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Spin spinning={sliderLoading}>
                {isItemSliderLoaded && images.length > 0 && (
                  <ImageGallery
                    items={images}
                    useBrowserFullscreen={false}
                    showBullets={false}
                  />
                )}
              </Spin>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ fontSize: "16px" }}>
              <Title level={5}>Facts and Features</Title>
              <Row>
                <Col span={6}>1</Col>
                <Col span={6}>2</Col>
                <Col span={6}>3</Col>
                <Col span={6}>4</Col>
              </Row>
              <div className="item-feature-list-wrapper">
                {item?.item_size && (
                  <Col span={6}>
                    <div className="item-feature">
                      <div className="item-feature__icon">
                        <PlusSquareOutlined />
                      </div>
                      <div className="item-feature__text-content">
                        <h5 className="item-feature__title">Area</h5>
                        <span className="item-feature__sub-title">
                          {item?.item_size} {item?.uom?.name}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
                {item?.road_size && (
                  <Col span={6}>
                    <div className="item-feature">
                      <div className="item-feature__icon">
                        <FontAwesomeIcon icon={faRoad} />
                      </div>
                      <div className="item-feature__text-content">
                        <h5 className="item-feature__title">Road Size</h5>
                        <span className="item-feature__sub-title">
                          {item?.road_size}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
                {item?.facing?.name && (
                  <Col span={6}>
                    <div className="item-feature">
                      <div className="item-feature__icon">
                        <FontAwesomeIcon icon={faArrowsH} />
                      </div>
                      <div className="item-feature__text-content">
                        <h5 className="item-feature__title">Facing</h5>
                        <span className="item-feature__sub-title">
                          {item?.facing?.name}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
                {item?.plot_position?.name && (
                  <Col span={6}>
                    <div className="item-feature">
                      <div className="item-feature__icon">
                        <FontAwesomeIcon icon={faChevronRight} />
                      </div>
                      <div className="item-feature__text-content">
                        <h5 className="item-feature__title">Position</h5>
                        <span className="item-feature__sub-title">
                          {item?.plot_position?.name}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
                {item?.block_name && (
                  <Col span={6}>
                    <div className="item-feature">
                      <div className="item-feature__icon">
                        <FontAwesomeIcon icon={faBold} />
                      </div>
                      <div className="item-feature__text-content">
                        <h5 className="item-feature__title">Block</h5>
                        <span className="item-feature__sub-title">
                          {item?.block_name}
                        </span>
                      </div>
                    </div>
                  </Col>
                )}
                <Col span={6}>
                  <div className="item-feature">
                    <div className="item-feature__icon">
                      <FontAwesomeIcon icon={faMoneyBill1} />
                    </div>
                    <div className="item-feature__text-content">
                      <h5 className="item-feature__title">Booking Money</h5>
                      <span className="item-feature__sub-title">
                        {new Intl.NumberFormat("en-BD", {
                          style: "currency",
                          currency: "BDT",
                        }).format(
                          item?.booking_money ? item?.booking_money : 0
                        )}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="item-feature">
                    <div className="item-feature__icon">
                      <FontAwesomeIcon icon={faComment} />
                    </div>
                    <div className="item-feature__text-content">
                      <h5 className="item-feature__title">Price Negotiable</h5>
                      <span className="item-feature__sub-title">Yes</span>
                    </div>
                  </div>
                </Col>
                {projectCategoryId === 1 && (
                  <>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faBed} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">Bedroom</h5>
                          <span className="item-feature__sub-title">
                            {item?.number_of_bedroom}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faSpoon} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">Dining</h5>
                          <span className="item-feature__sub-title">
                            {item?.number_of_living_and_dining}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faCutlery} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">Kitchen</h5>
                          <span className="item-feature__sub-title">
                            {item?.number_of_kitchen}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faUniversity} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">Balcony</h5>
                          <span className="item-feature__sub-title">
                            {item?.number_of_balcony}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faBath} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">Toilet</h5>
                          <span className="item-feature__sub-title">
                            {item?.number_of_toilet}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faSwimmer} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">Swimming pool</h5>
                          <span className="item-feature__sub-title">
                            {item?.number_of_swimming_pool}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faArrowsUpDown} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">Lift</h5>
                          <span className="item-feature__sub-title">
                            {item?.number_of_lift}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faUniversalAccess} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">GYM</h5>
                          <span className="item-feature__sub-title">
                            {item?.number_of_gym}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="item-feature">
                        <div className="item-feature__icon">
                          <FontAwesomeIcon icon={faUniversalAccess} />
                        </div>
                        <div className="item-feature__text-content">
                          <h5 className="item-feature__title">Parking Price</h5>
                          <span className="item-feature__sub-title">
                            {new Intl.NumberFormat("en-BD", {
                              style: "currency",
                              currency: "BDT",
                            }).format(
                              item?.parking_price ? item?.parking_price : 0
                            )}
                          </span>
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </div>
              <Divider></Divider>
            </Col>
          </Row>
          {projectCategoryId === 1 && (
            <Row>
              <Col span={24}>
                <Title level={5}>Amenities</Title>
                <div style={{ fontSize: 16 }}>
                  <ul>
                    {item?.conference_hall_flag == 1 && (
                      <li>Conference Hall</li>
                    )}
                    {item?.parking_area_flag == 1 && <li>Parking</li>}
                    {item?.security_flag == 1 && <li>Security</li>}
                    {item?.cctv_flag == 1 && <li>CCTV</li>}
                    {item?.heating_flag == 1 && <li>Central Heating</li>}
                    {item?.cooling_flag == 1 && <li>Central Cooling</li>}
                    {item?.cable_tv_flag == 1 && <li>Cable Tv</li>}
                    {item?.internet_flag == 1 && <li>Shared Internet</li>}
                  </ul>
                </div>
                <Divider></Divider>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <Flex justify="space-between">
                <Title level={5}>Description</Title>
                <Radio.Group
                  options={options}
                  onChange={onChangeDescriptionLanguage}
                  value={descriptionLanguage}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Flex>

              {descriptionLanguage === "English" && (
                <div
                  style={{ fontSize: "16px" }}
                  dangerouslySetInnerHTML={{
                    __html: item?.description ? item?.description : "",
                  }}
                ></div>
              )}

              {descriptionLanguage === "Bangla" && (
                <div
                  style={{ fontSize: "16px" }}
                  dangerouslySetInnerHTML={{
                    __html: item?.description_natural_language
                      ? item?.description_natural_language
                      : "",
                  }}
                ></div>
              )}
              <div className="mb-2"> </div>
              <div style={{ fontSize: "16px" }}>
                {" "}
                Project Name: {item?.project?.project_name}
              </div>
              <div style={{ fontSize: "16px" }}>
                {" "}
                Project Location: {item?.project?.project_location}
              </div>
              <Divider></Divider>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Flex justify="space-between">
                <Title level={5}>Terms & Conditions</Title>
                <Radio.Group
                  options={options}
                  onChange={onChangeTermsConditionLanguage}
                  value={termsConditionLanguage}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Flex>
              {termsConditionLanguage === "English" && (
                <div
                  style={{ fontSize: "16px" }}
                  dangerouslySetInnerHTML={{
                    __html: item?.terms_condition ? item?.terms_condition : "",
                  }}
                ></div>
              )}
              {termsConditionLanguage === "Bangla" && (
                <div
                  style={{ fontSize: "16px" }}
                  dangerouslySetInnerHTML={{
                    __html: item?.terms_condition_natural_language
                      ? item?.terms_condition_natural_language
                      : "",
                  }}
                ></div>
              )}
              <div className="mb-2"> </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col span={24}>
              {item?.youtube_id && (
                <YouTube videoId={item.youtube_id} opts={opts} />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 5 }}>
          <Row justify={"center"}>
            <Col span={22}>
              <Row>
                <Col span={24}>
                  <Card size="small" bordered={true}>
                    <div>
                        <div className="phone-number">
                          <p className="ml-3">
                            <strong className="text-danger">Hot Line</strong>
                            <br />
                            <span className="">
                              Call:<strong>{company?.hotline_no}</strong>
                            </span>
                          </p>
                        </div>
                        <div className="contact-logo ">
                          <img src={companyLogo} alt="contact-logo" />
                        </div>
                    </div>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Title level={5}> Featured Property</Title>
                  <List
                    grid={{
                      gutter: 20,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 1,
                      xl: 1,
                      xxl: 1,
                    }}
                    dataSource={featuresProperties}
                    renderItem={(item) => (
                      <List.Item className="mb-4">
                        <Skeleton
                          avatar
                          title={false}
                          loading={itemLoading}
                          active
                        >
                          <Link
                            className="text-decoration-none"
                            to={`/items/${item.id}`}
                          >
                            <Card
                              size="small"
                              bordered={true}
                              cover={
                                <>
                                  <img
                                    alt={item.item_id + "image"}
                                    src={
                                      API_URL +
                                      "/image-download/" +
                                      item.item_brand_photo
                                    }
                                  />
                                </>
                              }
                              onClick={() =>
                                setItemId(item.id ? item.id + "" : "")
                              }
                            >
                              <Flex justify={"space-between"}>
                                <a
                                  style={{ fontSize: "17px", color: "#1677ff" }}
                                >
                                  {new Intl.NumberFormat("en-BD", {
                                    style: "currency",
                                    currency: "BDT",
                                  }).format(item.net_price)}
                                </a>

                                <div style={{ fontSize: "17px" }}>
                                  {item.item_size} {item.uom?.name}
                                </div>
                              </Flex>
                              <div style={{ fontSize: "15px" }}>
                                <i className="bi bi-geo-alt"></i>
                                {item.project?.project_location}
                              </div>
                            </Card>
                          </Link>
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ItemDetails;
