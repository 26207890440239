import { Col, Row, Skeleton } from "antd";
import Title from "antd/es/typography/Title";
import ICompany from "../../interfaces/ICompany";


interface HeaderComponentProps {
  company: ICompany | undefined;
}

function Address({ company }: HeaderComponentProps) {

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Skeleton active loading={company==null}/>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <div>
            <Title level={5}>{company?.office_1_addr_name}</Title>
            <br />
            <div>{company?.office_1_address}</div>
            <br />
            <div>
              {company?.office_1_telephone_no && (
                <>
                  Telephone : {company?.office_1_telephone_no} <br />
                </>
              )}

              {company?.office_1_mobile_no &&
                `Mobile : ${company?.office_1_mobile_no}`}
            </div>
            <br />
            <div>
              {company?.office_1_email && `Email : ${company?.office_1_email}`}{" "}
              <br />
              Web : https://southstargroupbd.com
            </div>
            <br />
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <div>
            <Title level={5}>{company?.office_2_addr_name}</Title>
            <br />
            <div>{company?.office_2_address}</div>
            <br />
            <div>
              {company?.office_2_telephone_no && (
                <>
                  Telephone : {company?.office_2_telephone_no} <br />
                </>
              )}

              {company?.office_1_mobile_no &&
                `Mobile : ${company?.office_1_mobile_no}`}
            </div>
            <br />
            <div>
              {company?.office_2_email && `Email : ${company?.office_2_email}`}{" "}
              <br />
              Web : https://southstargroupbd.com
            </div>
            <br />
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <div>
            <Title level={5}>{company?.office_3_addr_name}</Title>
            <br />
            <div> {company?.office_3_address} </div>
            <br />
            <div>
              {company?.office_3_telephone_no && (
                <>
                  Telephone : {company?.office_3_telephone_no} <br />
                </>
              )}{" "}
              {company?.office_1_mobile_no &&
                `Mobile : ${company?.office_1_mobile_no}`}
            </div>
            <br />
            <div>
              {company?.office_3_email && `Email : ${company?.office_3_email}`}{" "}
              <br />
              Web : https://southstargroupbd.com
            </div>
            <br />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Address;
