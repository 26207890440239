
function News() {
  return (
    <div>
      <div>News</div>
      <div>News</div>
      <div>News</div>
      <div>News</div>

    </div>
  )
}

export default News;