import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, theme } from "antd";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Link from "antd/es/typography/Link";
import ICompany from "../../interfaces/ICompany";
import { API_URL } from "../../settings";

interface HeaderComponentProps {
  company: ICompany | undefined;
}

function HeaderComponent({ company }: HeaderComponentProps) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <div>
            <img
              style={{ marginTop: "5px", marginBottom: "5px" }}
              height={"44px"}
              src={API_URL + "/image-download/" + company?.company_logo_name}
            />
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <div>
            <i className="fa-solid fa-phone-volume"></i>
          </div>
          <div
            style={{ marginTop: "5px", lineHeight: "20px", fontSize: "17px" }}
          >
            {company?.company_phone}
            <br />
            {company?.company_mobile}
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
        >
          <div style={{ marginTop: "10px" }}>
            {" "}
            <Link
              style={{ fontSize: "18px" }}
              href={`mailto:${company?.company_email}`}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              &nbsp;{company?.company_email}
            </Link>{" "}
          </div>
        </Col>
      </Row>
    </>
  );
}

export default HeaderComponent;
