import { Button, Card, Col, Flex, List, Row, Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IItem from "../../interfaces/IItem";
import { API_URL } from "../../settings";
import Title from "antd/es/typography/Title";

function Items() {
  const [initItemLoading, setInitItemLoading] = useState(true);
  const [itemLoading, setItemLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const [cardItems, setCardItems] = useState<IItem[]>([]);

  const onLoadMore = () => {
    setCurrentPage(currentPage + 1);
    setItemLoading(true);
    axios
      .get(`${API_URL}/public/items/published?page=${currentPage}&per_page=12`)
      .then((response) => {
        response.data.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["loading"] = true;
        });
        const newData = cardItems.concat(response.data.data);
        setCardItems(newData);
        setItemLoading(false);
        setIsLastPage(response.data.next_page_url ? false : true);
        window.dispatchEvent(new Event("resize"));
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setItemLoading(false);
      });
  };

  const loadMore =
    !isLastPage && !initItemLoading && !itemLoading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>Load more</Button>
      </div>
    ) : null;

  const getItemList = () => {
    setItemLoading(true);
    axios
      .get(`${API_URL}/public/items/published?page=${currentPage}&per_page=12`)
      .then((response) => {
        response.data.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setCardItems(response.data.data);
        setInitItemLoading(false);
        setInitItemLoading(false);
        setCurrentPage(currentPage + 1);
        setIsLastPage(response.data.next_page_url ? false : true);
        setItemLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setItemLoading(false);
      });
  };

  useEffect(() => {
    getItemList();

    return () => {};
  }, []);

  return (
    <>
      <Row justify={"center"}>
        <Title level={4}>Properteis</Title>
      </Row>
      <Row justify={"center"}>
        <Col span={20}>
          <List
            loadMore={loadMore}
            loading={initItemLoading}
            grid={{
              gutter: 20,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={cardItems}
            renderItem={(item) => (
              <List.Item className="mb-4">
                <Skeleton avatar title={false} loading={itemLoading} active>
                  <Link
                    className="text-decoration-none"
                    to={`/items/${item.id}`}
                  >
                    <Card
                      size="small"
                      bordered={true}
                      cover={
                        <>
                          <img
                            style={{ height: "217px" }}
                            alt={item.item_id + "image"}
                            src={
                              API_URL +
                              "/image-download/" +
                              item.item_brand_photo
                            }
                          />
                        </>
                      }
                    >
                      <Flex justify={"space-between"}>
                        <a style={{ fontSize: "17px", color: "#1677ff" }}>
                          {new Intl.NumberFormat("en-BD", {
                            style: "currency",
                            currency: "BDT",
                          }).format(item.net_price)}
                        </a>

                        <div style={{ fontSize: "17px" }}>
                          {item.item_size} {item.uom?.name}
                        </div>
                      </Flex>
                      <div style={{ fontSize: "15px" }}>
                        <i className="bi bi-geo-alt"></i>
                        {item.project?.project_location}
                      </div>
                    </Card>
                  </Link>
                </Skeleton>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}

export default Items;
