import { Layout, theme } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ICompany from "../../interfaces/ICompany";
import { API_URL } from "../../settings";
import CorporateProfile from "../AboutUs/CorporateProfile";
import MissionVisionValues from "../AboutUs/MissionVisionVaues";
import CSRDetails from "../CSR/CSRDetails";
import CSRS from "../CSR/CSRS";
import Contact from "../Contact/Contact";
import FooterComponent from "../Footer/FooterComponent";
import { default as Galleries, default as Gallery } from "../Gallery/Galleries";
import GalleryDetails from "../Gallery/GalleryDetails";
import HeaderComponent from "../Header/HeaderComponent";
import HeaderMenu from "../Header/HeaderMenu";
import HomePage from "../HomePage/HomePage";
import ItemDetails from "../Item/ItemDetails";
import Items from "../Item/Items";
import ChairmanMessage from "../Messages/ChairmanMessage";
import DirectorMessage from "../Messages/DirectorMessage";
import ManagingDirectorMessage from "../Messages/ManagingDirectorMesage";
import News from "../News/News";
import Projects from "../Project/Projects";

const { Header, Footer, Sider, Content } = Layout;

function ContentComponent() {
  const [itemLoading, setCompanyLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<ICompany | undefined>(undefined);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    getCompany();

    return () => {};
  }, []);

  const getCompany = () => {
    setCompanyLoading(true);
    axios
      .get(`${API_URL}/companies/1`)
      .then((response) => {
        setCompany(response.data);
        setCompanyLoading(false);
      })
      .catch((err) => {
        setCompanyLoading(false);
        console.log("server error", err);
      });
  };

  return (
    <>
      <Layout style={{ background: colorBgContainer }}>
        <Header
          className="main-header"
          style={{
            background: colorBgContainer,
            borderBottom: "1px solid #eff2f5de",
          }}
        >
          <HeaderComponent company={company}></HeaderComponent>
        </Header>
        <HeaderMenu></HeaderMenu>
        <Content
          style={{
            // margin: '24px 16px',
            padding: 0,
            // minHeight: 360,
            background: colorBgContainer,
            // borderTop: '1px solid #e6ebf080'
          }}
        >
          <div>
            <Routes>
              <Route path="/" element={<HomePage company={company} />} />
              <Route path="corporate-profile" element={<CorporateProfile />} />
              <Route
                path="mission-and-vision"
                element={<MissionVisionValues />}
              />
              <Route path="message-chairman" element={<ChairmanMessage />} />
              <Route
                path="message-managing-director"
                element={<ManagingDirectorMessage />}
              />
              <Route path="message-director" element={<DirectorMessage />} />
              <Route path="news" element={<News />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="contact" element={<Contact />} />
              <Route path="items/:id" element={<ItemDetails company={company} />} />
              <Route path="items" element={<Items />} />
              <Route path="projects" element={<Projects />} />
              <Route path="projects/:id" element={<Projects />} />
              <Route path="galleries" element={<Galleries />} />
              <Route path="galleries/:id" element={<GalleryDetails company={company} />} />
              <Route path="csrs" element={<CSRS />} />
              <Route path="csrs/:id" element={<CSRDetails company={company} />} />
            </Routes>
          </div>
        </Content>
        {/* <FooterSeparator></FooterSeparator> */}
        <Footer style={{ marginTop: "20px" }}>
          <FooterComponent company={company}></FooterComponent>
        </Footer>
      </Layout>
    </>
  );
}

export default ContentComponent;
