import { Carousel, Col, Row } from "antd";
import React from "react";

import slider2 from "../../assets/images/slider-2.jpg";
import slider1 from "../../assets/images/slider_rel1.jpg";
import ICompany from "../../interfaces/ICompany";
import { API_URL } from "../../settings";
interface HeaderComponentProps {
  company: ICompany | undefined;
}
function SliderComponent({ company }: HeaderComponentProps) {
  const onChange = (currentSlide: number) => {};

  const sliderStyle: React.CSSProperties = {
    height: "500px",
    width: "100%",
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Carousel
            dotPosition="bottom"
            fade={true}
            autoplay={true}
            afterChange={onChange}
          >
            {company?.company_sliders.map((slider) => (
              <div>
                <img
                  style={sliderStyle}
                  src={API_URL + "/image-download/" + slider?.image_name}
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </Col>
      </Row>
    </>
  );
}

export default SliderComponent;
