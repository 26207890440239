import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import { Col, List, Row, Space } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import IProject from '../../interfaces/IProject';
import { API_URL } from '../../settings';

function Projects() {

  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    getProjectItems()

    return () => {
    }
  }, [])


  const getProjectItems = () => {
    axios.get(`${API_URL}/public/projects/published`)
      .then((response) => {
        response.data.data.map((x: { [x: string]: any; }) => {
          x['key'] = x.id
        })
        setProjects(response.data.data);


      }).catch(err => {
        console.log("server error", err);
      });
  }

  const data = Array.from({ length: 23 }).map((_, i) => ({
    href: 'https://ant.design',
    title: `ant design part ${i}`,
    avatar: `https://xsgames.co/randomusers/avatar.php?g=pixel&key=${i}`,
    description:
      'Ant Design, a design language for background applications, is refined by Ant UED Team.',
    content:
      'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
  }));

  const IconText = ({ icon, text }: { icon: React.FC; text: string }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  return (
    <>
      <Row justify={'center'}>
        <Col span={19}>
          <List
            itemLayout="vertical"
            size="small"
            pagination={{
              onChange: (page) => {
              },
              pageSize: 3,
            }}
            dataSource={projects}
            footer={
              <div>
                <b>ant design</b> footer part
              </div>
            }
            renderItem={(project) => (
              <List.Item
                key={project.project_name}
                actions={[
                  <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                  <IconText icon={LikeOutlined} text="156" key="list-vertical-like-o" />,
                  <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
                ]}
                extra={
                  <img
                    width={272}
                    alt="logo"
                    src={API_URL + '/image-download/' + project.project_layout_photo}
                  />
                }
              >
                {project.project_location}
              </List.Item>
            )}
          />
        </Col>

      </Row>
    </>
  )
}

export default Projects;