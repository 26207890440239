import { Col, Menu, MenuProps, Row } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function HeaderMenu() {
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();

  const onClickMenu: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    if (
      !(
        e.key === "Facebook" ||
        e.key === "YouTube" ||
        e.key === "Twitter" ||
        e.key === "LinkedIn" ||
        e.key === "realestate" ||
        e.key === "agro"
      )
    ) {
      navigate(e.key);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Home",
      key: "",
    },

    {
      label: "Properties",
      key: "items",
    },
    {
      label: "About Us",
      key: "aboutUs",
      children: [
        {
          label: "Corporate Profile",
          key: "corporate-profile",
        },
        {
          label: "Mission, Vision and Values",
          key: "mission-and-vision",
        },
      ],
    },
    {
      label: "Messages",
      key: "messages",
      // icon: <MailOutlined />,
      children: [
        {
          label: "Chairman Message",
          key: "message-chairman",
        },
        {
          label: "Managening Director Message",
          key: "message-managing-director",
        },
        {
          label: "Director Message",
          key: "message-director",
        },
      ],
    },
    {
      label: "Enterprise",
      key: "enterprise",
      // icon: <MailOutlined />,
      children: [
        {
          label: (
            <a
              style={{ textDecoration: "none" }}
              href="https://realestate.southstargroupbd.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Real Estate
            </a>
          ),
          key: "realestate",
        },
        {
          label: "Agro Farm",
          key: "agro",
        },
      ],
    },
    {
      label: "CSR",
      key: "csrs",
    },
    {
      label: "Gallery",
      key: "galleries",
    },
    {
      label: "Media",
      key: "media",
      children: [
        {
          label: (
            <a
              style={{ textDecoration: "none" }}
              href="https://www.facebook.com/southstargroup"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          ),
          key: "Facebook",
        },
        {
          label: (
            <a
              style={{ textDecoration: "none" }}
              href="https://www.youtube.com/channel/UCSFO6OtkndagAUMp7icHrzA"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </a>
          ),
          key: "YouTube",
        },
        {
          label: (
            <a
              style={{ textDecoration: "none" }}
              href="https://twitter.com/SouthStarGroup1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          ),
          key: "Twitter",
        },
        {
          label: (
            <a
              style={{ textDecoration: "none" }}
              href="https://www.linkedin.com/in/south-star-group-5a153b312/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          ),
          key: "LinkedIn",
        },
      ],
    },
    // {
    //     label: 'News',
    //     key: 'news',
    // },
    // {
    //     label: 'Contact',
    //     key: 'contact',
    //     // icon: <MailOutlined />,
    // }
  ];

  return (
    <>
      <Row className="mb-1" justify={"center"} align="middle">
        <Col >
          <Menu
            className="default-text"
            style={{ border: "none", minWidth: 0, flex: "auto" }}
            onClick={onClickMenu}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
        </Col>
      </Row>
    </>
  );
}

export default HeaderMenu;
