import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/icon-bootstrap.min.css';
import ContentComponent from './Components/Content/ContentComponent';

function App() {
  return (
    <div className="App">
      <ContentComponent />
    </div>
  );
}

export default App;
