import {
  Card,
  Col,
  Flex,
  List,
  Radio,
  RadioChangeEvent,
  Row,
  Skeleton,
  Spin,
} from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { useEffect, useState } from "react";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { Link, useParams } from "react-router-dom";
import companyLogo from "../../assets/images/manor_logo.png";
import IGallery from "../../interfaces/IGallery";
import { API_URL } from "../../settings";
import HtmlContent from "../HtmlContent";
import "./style.css";
import ICompany from "../../interfaces/ICompany";
interface HeaderComponentProps {
  company: ICompany | undefined;
}
function GalleryDetails({ company }: HeaderComponentProps) {
  let { id } = useParams();

  const [isGallerySliderLoaded, setGallerySliderLoaded] =
    useState<boolean>(false);
  const [itemId, setGalleryId] = useState(id);
  const [itemLoading, setGalleryLoading] = useState<boolean>(false);
  const [sliderLoading, setSliderLoading] = useState<boolean>(false);

  const [messages, setMessages] = useState<string>();
  const [messagesBn, setMessagesBn] = useState<string>();

  const [item, setGallery] = useState<IGallery>();
  const [featuresProperties, setFeaturesProperties] = useState<IGallery[]>([]);
  const [images, setImages] = useState<ReactImageGalleryItem[]>([]);

  const [descriptionLanguage, setDescriptionLanguage] = useState("English");
  const options = [
    { label: "English", value: "English" },
    { label: "Bangla", value: "Bangla" },
  ];

  useEffect(() => {
    getGallery();
    getFeatureGalleries();

    return () => {};
  }, [itemId]);

  const getGallery = () => {
    setGalleryLoading(true);
    axios
      .get(`${API_URL}/galleries/${itemId}`)
      .then((response) => {
        setGallery(response.data);
        setMessages(response.data.description);
        setMessagesBn(response.data.description_bn);

        response.data.gallery_sliders.map(
          (x: { [x: string]: any; id: any }) => {
            x["key"] = x.id;
            x["name"] = x.image_name;
            x["url"] = API_URL + "/image-download/" + x.image_name;
            x["original"] = API_URL + "/image-download/" + x.image_name;
            x["thumbnail"] = API_URL + "/image-download/" + x.image_name;
            x["originalHeight"] = "500px";
            x["thumbnailHeight"] = "62px";
          }
        );

        setImages(response.data.gallery_sliders);
        setSliderLoading(false);
        setGallerySliderLoaded(true);
        setGalleryLoading(false);
      })
      .catch((err) => {
        setGalleryLoading(false);
        console.log("server error", err);
      });
  };

  const getFeatureGalleries = () => {
    axios
      .get(`${API_URL}/public/galleries/published?per_page=3`)
      .then((response) => {
        setFeaturesProperties(response.data.data);
      })
      .catch((err) => {
        console.log("server error", err);
      });
  };

  const onChangeDescriptionLanguage = ({
    target: { value },
  }: RadioChangeEvent) => {
    setDescriptionLanguage(value);
  };

  return (
    <>
      <Row justify={"center"} className="mt-1">
        <Col xs={{ span: 22 }} md={{ span: 14 }} span={14}>
          <Row>
            <Col span={24}>
              <Flex className="mb-2" justify={"space-between"}>
                <Title level={3}>{item?.title}</Title>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Spin spinning={sliderLoading}>
                {isGallerySliderLoaded && images.length > 0 && (
                  <>
                    <ImageGallery
                      items={images}
                      useBrowserFullscreen={false}
                      showBullets={false}
                    />
                    <br />
                  </>
                )}
              </Spin>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="default-text">
                <Radio.Group
                  options={options}
                  onChange={onChangeDescriptionLanguage}
                  value={descriptionLanguage}
                  optionType="button"
                  buttonStyle="solid"
                />
                {descriptionLanguage === "English" && (
                  <HtmlContent html={messages || ""} />
                )}
                {descriptionLanguage === "Bangla" && (
                  <HtmlContent html={messagesBn || ""} />
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 5 }}>
          <Row justify={"center"}>
            <Col span={22}>
              <Row>
                <Col span={24}>
                  <Card size="small" bordered={true}>
                    <div>
                        <div className="phone-number">
                          <p className="ml-3">
                            <strong className="text-danger">Hot Line</strong>
                            <br />
                            <span className="">
                              Call:<strong>{company?.hotline_no}</strong>
                            </span>
                          </p>
                        </div>
                        <div className="contact-logo mr-1">
                          <img src={companyLogo} alt="contact-logo" />
                        </div>
                    </div>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Title level={5}> Featured Property</Title>
                  <List
                    grid={{
                      gutter: 20,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 1,
                      xl: 1,
                      xxl: 1,
                    }}
                    dataSource={featuresProperties}
                    renderItem={(item) => (
                      <List.Item className="mb-4">
                        <Skeleton
                          avatar
                          title={false}
                          loading={itemLoading}
                          active
                        >
                          <Link
                            className="text-decoration-none"
                            to={`/galleries/${item.id}`}
                          >
                            <Card
                              size="small"
                              bordered={true}
                              cover={
                                <>
                                  <img
                                    alt={item.thumbnail_name + "image"}
                                    src={
                                      API_URL +
                                      "/image-download/" +
                                      item.thumbnail_name
                                    }
                                  />
                                </>
                              }
                              onClick={() =>
                                setGalleryId(item.id ? item.id + "" : "")
                              }
                            >
                              <div style={{ fontSize: "15px" }}>
                                {item.title}
                              </div>
                            </Card>
                          </Link>
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default GalleryDetails;
