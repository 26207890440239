import ICompany from "../../interfaces/ICompany";
import Address from "../Address/Address";
import Copyright from "../Copyright/Copyright";
interface HeaderComponentProps {
  company: ICompany | undefined;
}
function FooterComponent({ company }: HeaderComponentProps) {
  return (
    <>
      <Address company={company}></Address>
      <Copyright></Copyright>
    </>
  );
}

export default FooterComponent;
