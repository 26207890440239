import { MenuProps } from 'antd';
import { useState } from 'react';
import ICompany from '../../interfaces/ICompany';
import IItem from '../../interfaces/IItem';
import HeadingNews from '../HeadingNews/HeadingNews';
import Items from '../Item/Items';
import SliderComponent from '../Slider/SliderComponent';

const CurrencyFormatter = (amount: number, currencyCode: string) => {
    const formattedCurrency = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    }).format(amount);

    return <span>{formattedCurrency}</span>;
};
interface HeaderComponentProps {
    company: ICompany | undefined;
  }

function HomePage({ company }: HeaderComponentProps) {


    const [initItemLoading, setInitItemLoading] = useState(true);
    const [itemLoading, setItemLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);

    const [cardItems, setCardItems] = useState<IItem[]>([]);


    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a className='text-decoration-none' target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    1st menu item
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" className='text-decoration-none' rel="noopener noreferrer" href="https://www.aliyun.com">
                    2nd menu item
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a className='text-decoration-none' target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
                    3rd menu item
                </a>
            ),
        }
    ];



    return (
        <>
            <SliderComponent company={company}></SliderComponent>
            <HeadingNews company={company}></HeadingNews>
            <Items></Items>
            {/* Filter */}
            {/* <div className='mb-1'>
                <Row justify={'center'}>
                    <Col span={10}>
                        <Dropdown menu={{ items: items }}>
                            <a style={{ color: 'rgba(0,0,0,.85)' }} onClick={(e) => e.preventDefault()}>
                                <Space>
                                    Hover me
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                    </Col>
                </Row>
            </div> */}

        </>
    )
}

export default HomePage;