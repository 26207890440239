import { Col, Row } from 'antd';
import Link from 'antd/es/typography/Link';
function Copyright() {
    return (
        <>

            <Row justify={'center'} style={{ paddingTop: '10px', marginTop: '10px', borderTop: '1px solid #e6e6e6' }}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>Copyright © 2023 All rights reserved.</Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>Developed by: &nbsp;
                    <Link href="https://www.badrul.dev" target="_blank">badrul.dev
                    </Link>
                </Col>
            </Row >
        </>
    )
}

export default Copyright;